/***
 *
 * A helper for key down handling.
 *
 *
 *
 * Usage:
 *
 * <div use:keyDown={{key: "Escape", handler: onEsc}} />
 *
 * Can also pass array of options
 * <div use:keyDown={[
 *  {key: "Escape", handler: onEsc},
 *  {key: "Return", handler: onReturn}
 * ]}/>
 */

interface KeyDownOptions {
  key: string;
  handler: (ev: KeyboardEvent) => void;
  mod?: "shift" | "cmd" | "cmd+shift";
  stop?: boolean;
}

export function keyDown(node: HTMLElement, options: KeyDownOptions | KeyDownOptions[]) {
  // Make sure our options is an array;
  const arrOptions: KeyDownOptions[] = Array.isArray(options) ? options : [options];

  function onKeyDown(event: KeyboardEvent) {
    // A simple helper code to make sure we don't trigger key down events on events in inputs etc, UNLESS we register
    // the key down on a parent node (like the form element)
    if (event.target && event.target != document.body && !node.contains(event.target as Node)) {
      return;
    }
    arrOptions
      .filter((i) => filterOption(i, event))
      .forEach((i) => {
        if (i.stop) {
          event.stopImmediatePropagation();
          event.preventDefault();
        }
        i.handler(event);
      });
  }

  // Register
  document.addEventListener("keydown", onKeyDown);

  return {
    destroy: () => document.removeEventListener("keydown", onKeyDown)
  };
}

function filterOption(opt: KeyDownOptions, ev: KeyboardEvent): boolean {
  if (!ev.key || opt.key.toLowerCase() !== ev.key.toLowerCase()) {
    return false;
  }

  switch (opt.mod) {
    case "cmd":
      return ev.metaKey || ev.ctrlKey;
    case "shift":
      return ev.shiftKey;
    case "cmd+shift":
      return (ev.metaKey || ev.ctrlKey) && ev.shiftKey;
    default:
      return true;
  }
}
